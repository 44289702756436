import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../layouts'
import SEO from '../components/SEO'
import PostList from '../components/PostList'
import Container from '../components/Container'

class BlogListPage extends React.Component {
  render () {
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout
        location={this.props.location}
        siteMeta={this.props.data.site.siteMetadata}
      >
        <SEO
          title={this.props.data.site.siteMetadata.name + ' Blog'}
          description="Base64 Encoder Blog. Learn all about Base64 Encoding. What is Base64, How to Base64 encode and decode binary data, Why Base64 encoding is required, and also learn How to perform Base64 encoding and decoding in different programming languages like Java, Python, Javascript, Golang, and PHP."
          article={false}
          pathname={this.props.location.pathname}
        />
        <Container>
          <PostList
            posts={posts}
            siteMeta={this.props.data.site.siteMetadata}
          />
        </Container>
      </Layout>
    )
  }
}

export default BlogListPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            description
            featured_image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
